



.photos-container {
    width: 100%;
    margin: 0 auto;
    padding: 7rem 0 4rem 0;
}


.photo-gallery-banner {
    padding: 2rem 10px;
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: xx-large;
    font-weight: 700;
    box-shadow: 0px 0px 50px 1px rgba(0, 0, 0, 0.473);
    border-top: rgb(0, 0, 0) 3px solid;
    border-bottom: rgb(0, 0, 0)3px solid;
    background-image: linear-gradient(to left, rgba(222, 222, 222, 0.662), rgba(119, 117, 0, 0.461), rgba(221, 221, 221, 0.662));
}



hr {
    height: 25px;
    background-image: linear-gradient(to left, rgba(243, 243, 243, 0.662), rgb(0, 208, 255), rgba(243, 243, 243, 0.662));
    box-shadow: 0px 0px 50px 1px rgba(0, 0, 0, 0.473);
    border: none;
}



.video-wrapper {
    position: relative;
    text-align: center;
    padding: 5rem 0;
}



video {
    display: block;
    margin: auto;
    width: 100%;
    max-width: 950px;
    margin-bottom: 25px;
}

@media only screen and (max-width: 1000px) {
    .photo-gallery-banner {
        font-size: x-large;
    }
    
}