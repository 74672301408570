

.quick-quote-container {
    width: 100%;
    display: block;
    text-align: center;
    margin: 0 auto;
    padding: 7rem 0 4rem 0;
    
}

.quick-quote-banner {
    padding: 2rem 10px;
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: xx-large;
    font-weight: 700;
    box-shadow: 0px 0px 50px 1px rgba(0, 0, 0, 0.473);
    border-top: rgb(0, 0, 0) 3px solid;
    border-bottom: rgb(0, 0, 0)3px solid;
    background-image: linear-gradient(to left, rgba(222, 222, 222, 0.662), rgba(29, 186, 1, 0.731), rgba(221, 221, 221, 0.662));
}

.quick-quote-container p {
    padding: 3rem 10px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.662), rgba(0, 208, 255, 0.388), rgba(255, 255, 255, 0.662));
    font-size: medium;
    text-align: left;
    margin: 0 auto;
    width: 70%;
}
.quick-quote-container h4 {
    margin-top: 2rem;
    padding: 0 7px 0 7px;
}
.guaranteeBtn {
    margin: 10px auto;
    padding: 9px 25px;
    border-radius: 50px;
    color: rgb(255, 255, 255);
    font-size: small;
    font-weight: 500;
    background-color: rgb(0, 222, 0);
    width: 90%;
    box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.253);
}


@media only screen and (max-width: 1000px) {
    .quick-quote-banner {
        font-size: x-large;
    }
    .quick-quote-container p {
        width: 100%;
    }
}

/* @media only screen and (max-width: 600px) {
    .quick-quote-banner {
        font-size: large;
    }
    
} */