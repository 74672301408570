.about {
    margin: 0;
    text-align: left;
    background: rgb(247, 247, 247);
    display: flex;
    justify-content: center;
    align-items: top;
    
}


.about-banner {
    font-weight: 600;
    color: rgb(255, 255, 255);
    margin: 0 auto;
    font-size: xxx-large;
    word-break: keep-all;
    text-align: left;
    width: 100%;
    border-radius: 10px;
    padding-bottom: 0.5rem;
    padding-top: 1rem;
    padding-left: 1rem;
    background-image: linear-gradient(to right, rgb(0, 208, 255), rgba(192, 192, 192, 0.662));
    margin-bottom: 1rem;
    box-shadow: 0px 0px 45px 2px rgba(0, 0, 0, 0.253);
    text-shadow: 1px 1px 30px rgb(139, 139, 139);

}

.about-us-text-container {
    width: 80%;
    margin: 0 auto;
    padding: 4rem 7px 4rem 7px;
}
.about-us-text-container h3 {
    margin: 0;
    display: flex;
    color: rgb(11, 181, 211);
    word-break: keep-all;
    font-size: 4vh;
    padding-top: 11%;
    padding-bottom: 3%;
    font-weight: 100;
}

.about-us-text-container h1 {
    color: rgb(62, 59, 59);
    margin-bottom: 3rem;
    font-size: large;
    padding: 2rem 2rem;
}


.logo-container {
    background-color: rgb(255, 255, 255);
    display: flex;
    margin: 0;
    justify-content: space-evenly;
    box-shadow: 0px 0px 14px 1px rgb(0, 0, 0);
}

.logo-container img {
    height: 20vw;
    width: 27vw;
    padding: 15px;
    max-width: 200px;
    max-height: 140px;
    object-fit: contain;
}


.about-us-text-container p {
    margin: 0;
    font-size: large;
    word-break: keep-all;
    padding-left: 5px;
}


.services {
    display: block;
    justify-content: center;
    align-items: center;
    text-align: left;
    width: 100%;
    background-color: rgb(255, 255, 255);
    padding: 0 1rem;
    width: 100%;
    margin: 0 auto;
    padding: 1rem 7px;
}

.services-container {
    display: flex;
    align-items: top;
    margin: 0 auto;
    text-align: left;
    border-radius: 10px;
    width: 80%;
    flex-wrap: wrap;
    font-size: x-large;
    flex-direction: row;
    padding: 4rem 0;
    letter-spacing: -1px;
    word-spacing: 1px;
}

.services-banner {
    font-weight: 600;
    color: rgb(255, 255, 255);
    margin: 0 auto;
    font-size: xxx-large;
    word-break: keep-all;
    text-align: left;
    width: 100%;
    border-radius: 10px;
    padding-bottom: 0.5rem;
    padding-top: 1rem;
    padding-left: 1rem;
    background-image: linear-gradient(to right, rgb(0, 208, 255), rgba(192, 192, 192, 0.662));
    margin-bottom: 1rem;
    box-shadow: 0px 0px 45px 2px rgba(0, 0, 0, 0.253);
    text-shadow: 1px 1px 30px rgb(139, 139, 139);
}

.services-container p {
    padding-left: 5px;
    margin: 0;
    font-size: large;
}




.services-section h6 {
    width: 65%;
    margin: 0 auto;
    text-align: left;
    font-weight: 400;
    color: rgb(0, 0, 0);
    font-size: small;
    padding-left: 5px;
    padding-bottom: 2rem;
}

.services-section a {
    text-decoration: none;
}

.service-we-dont-do {
    color: red;
}



@media only screen and (max-width: 1000px) {
    .about-us-text-container, .services-container {
        width: 100%;
    }
    .about-banner, .services-banner  {
        font-size: xx-large;
    }
    .about-us-text-container p, .services-container p {
        font-size: medium;
    }
}


@media only screen and (max-width: 600px) {
    .about-banner, .services-banner {
        font-size: x-large;
    }
    .about-us-text-container p, .services-container p {
        font-size: small;
    }
}

