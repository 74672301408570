


.company-logo {
    margin: 0;
    padding-top: 3rem;
    height: 50%;
    width: 90%;
    max-width: 1000px;
    object-fit: contain;
}


.vehicle-icons {
    width: 100%;
    display: flex;
    margin: 0;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
}

.vehicle-icons img {
    width: 15%;
    max-width: 130px;
    object-fit: contain;
    aspect-ratio: 3/2;
}
.vehicle-icons-inner-div-top {
    display: flex;
    margin: 0;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-top: 4.9rem;
}

.vehicle-icons-inner-div-bottom {
    display: flex;
    margin: 3rem 0;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    padding: 10px 0;
    
}

h1 {
    font-size: x-large;
    font-weight: 100;
    color: rgb(0, 0, 0);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    padding: 1.5rem 10px;
    text-align: center;
    background-image: linear-gradient(to left, rgba(222, 222, 222, 0.662), rgba(195, 192, 8, 0.571), rgba(221, 221, 221, 0.662));
    font-weight: 600;
    width: 100%;
    box-shadow: 0px 0px 50px 1px rgba(0, 0, 0, 0.473);
    border-top: rgb(0, 0, 0) 3px solid;
    border-bottom: rgb(0, 0, 0)3px solid;
    
}

.locations {
    margin: auto;
    padding: 7px;
    font-weight: 100;
    font-size: medium;
    padding-top: 2rem;
    text-align: center;
}




.contacts {
    color: black;
}





@media only screen and (max-width: 1000px) {
    
    
    h1 {
        font-size: large;
        margin-bottom: 1rem;
    }
    .vehicle-icons-inner-div-bottom {
        margin: 1rem 0;
    }
    
}

@media only screen and (max-width: 700px) {
    .company-logo {
        width: 100%;
    }
    .locations {
        font-size: medium;
    }
    .company-logo {
        padding-top: 2rem;
    }
    
    
}

@media only screen and (max-width: 600px) {
    
    h1 {
        font-size: small;
        padding: 1rem 10px;
    }
    
}

