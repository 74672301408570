
.faq-container {
    margin: 0 auto;
    background: rgb(255, 255, 255);
    width: 100%;
    
}

.faq-overlay {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    padding: 4rem 2rem;
    margin: 0;
}

.faq-header {
    font-weight: 600;
    color: rgb(255, 255, 255);
    margin: 0 auto;
    font-size: xxx-large;
    word-break: keep-all;
    text-align: left;
    width: 100%;
    padding-top: 1rem;
    padding-left: 1rem;
    border-radius: 10px;
    padding-bottom: 0.5rem;
    background-image: linear-gradient(to right, rgb(0, 208, 255), rgba(192, 192, 192, 0.662));
    margin-bottom: 2rem;
    box-shadow: 0px 0px 45px 2px rgba(0, 0, 0, 0.253);
    text-shadow: 1px 1px 30px rgb(139, 139, 139);
}



.faq {
    margin: 0 auto;
    display: flex;
    gap: 20px;
}

.faq-container p {
    margin: 0 auto;
    word-break: keep-all;
    font-size: large;
    color: #626262;
    line-height: 27px;
    padding: 0 10px 10px 0;
    word-break: keep-all;
    text-align: left;
}

.faq-main-text h5 {
    text-align: center;
    color: rgb(11, 181, 211);
    margin: 30px auto;
    font-size: 4vw;
    word-break: keep-all;
    text-align: left;
    padding-bottom: 2rem;
    font-weight: 100;
}

.question-cards h3 {
    margin: 0;
    padding-bottom: 10px;
    font-size: x-large;
    font-weight: 600;
    word-break: keep-all;
    text-align: left;
}



.question-cards {
    margin: 0 ;
    background: rgb(255, 255, 255);
    text-align: center;
    background-color: rgb(246, 246, 246);
    border-radius: 15px;
    padding: 1rem;
    width: 40%;
    box-shadow: 0px 0px 45px 2px rgba(0, 0, 0, 0.253);
}

.question-cards-bottom {
    margin: 20px 0;
    margin-right: 20px;
    background: rgb(255, 255, 255);
    text-align: center;
    background-color: rgb(246, 246, 246);
    border-radius: 15px;
    padding: 1rem;
    width: 100%;
    
    box-shadow: 0px 0px 45px 2px rgba(0, 0, 0, 0.253);
}

.question-cards-bottom h3 {
    margin: 0;
    padding-bottom: 10px;
    font-size: x-large;
    font-weight: 600;
    word-break: keep-all;
    text-align: left;
}


.question-cards span {
    color: red;
}
.leaseBtn {
    margin: 10px auto;
    padding: 9px 25px;
    border-radius: 50px;
    color: rgb(0, 0, 0);
    font-size: small;
    font-weight: 500;
    background-color: rgb(0, 222, 0);
    width: 100%;
    box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.253);
}





@media only screen and (max-width: 1000px) {
    .faq-container p {
        font-size: medium;
    }
    .question-cards h3, .question-cards-bottom h3 {
        font-size: large;
    }
    .faq-overlay {
        width: 100%;
        padding: 4rem 7px 4rem 7px;
    }
    
    
    .faq {
        display: block;
        width: 100%;
    }
    .question-cards {
        margin: 20px auto;
        width: 100%;
    }
    .faq-header {
        font-size: xx-large;
    }
}

@media only screen and (max-width: 600px) {
    .faq-container p {
        line-height: 23px;
    }
    .faq-header {
        font-size: x-large;
    }
    .faq-container p {
        font-size: small;
    }
    .question-cards h3, .question-cards-bottom h3 {
        font-size: medium;
    }
    

    .question-cards, .question-cards-bottom {
        padding: 1rem 11px;
    }
    
}

