* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  
}

h1 {
  margin: 0;
}
